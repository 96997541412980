import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

import { Button } from './Button'

const StyledNav = styled.nav`
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4rem;
`

const StyledUl = styled.ul`
    margin: 0;
    padding: 0;
    display: flex;
    flex: 1;
    grid-column-gap: 3rem;
    list-style: none;
    font-size: 1.125rem;
    font-weight: normal;
    color: white;

    @media (max-width: 600px) {
        display: flex;
        position: fixed;
        justify-content: space-around;
        bottom: 0;
        left: 0;
        right: 0;
        color: black !important;
        background: black;
        z-index: 9999;
    }
`

const StyledLi = styled.li`
    @media (max-width: 600px) {
        padding: 1rem;
    }
`

export const Navigation = () => {
    return (
        <>
            <StyledNav>
                <a href="/" rel="nofollow">
                    <StaticImage src="../images/logo.svg" alt="neginfood logo" height={48} layout="fixed" placeholder="tracedSVG" />
                </a>
                <StyledUl>
                    <StyledLi>
                        <a href="#about" rel="nofollow">درباره ما</a>
                    </StyledLi>
                    <StyledLi>
                        <a href="#mikhak" rel="nofollow">سامانه میخک</a>
                    </StyledLi>
                </StyledUl>
                <Button label="تماس با ما" link="#contact" rel="nofollow" />
            </StyledNav>
        </>
    )
}