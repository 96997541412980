import React from 'react'
import styled from 'styled-components'

import { StaticImage } from 'gatsby-plugin-image'

import Seo from '../components/Seo'
import { Header } from '../components/Header'
import { Brands } from '../components/Brands'
import { Contact } from '../components/Contact'

const Main = styled.main`
    max-width: 1440px;
    margin: 0 auto;
`

const Section = styled.section`
    max-width: 1440px;
    margin: auto;
    padding: 3rem 0;
    background: ${props => props.bgColor || "#f4f4f4"};
    text-align: ${props => props.textAlign || "right"};
`

const Content = styled.article`
    width: 90%;
    margin: 0 auto;
    h2 {
        margin-bottom: 2rem;
    }
    p {
        font-size: 1.25rem;
        font-weight: normal;
        line-height: 2.5rem;
        text-align: right;
    }
`

const Footer = styled.footer`
    width: 90%;
    max-width: 1440px;
    margin: 0 auto;
    border-top:1px solid #C0C8CA;
    padding: 1rem 0rem;
    display: flex;
    gap: 1rem;
    @media (max-width: 600px) {
        margin-bottom: 3rem;
    }
`

const IndexPage = () => {
    
    return (
        <>
            <Seo />
            <Header />
            <Main>
                <Brands />
                <Section id="about" textAlign="center">
                    <Content>
                        <h2>درباره ما</h2>
                        <p>شرکت نگین با داشتن کادری مجرب و متخصص در زمینه واردات محصولات غذایی باکیفیت ایرانی از معتبرترین تولیدکنندگان ایران به‌صورت گسترده فعالیت می‌نماید.</p>
                        <p>تعهد، صداقت، سرعت عمل و تخصص ارکان اصلی شرکت نگین است که به آن افتخار می‌نماید.</p>
                        <p>از عمده‌ترین زمینه‌های فعالیت شرکت میتوان از واردات انواع خشکبار، شیرینی‌جات، ادویه‌جات و انواع کنسرو و مرباجات نام برد.</p>
                    </Content>
                </Section>
                <Section id="mikhak" bgColor="#f9f9f9" textAlign="center">
                    <Content>
                        <h2>سامانه میخک</h2>
                        <p>سرویس ویژه جهت رفاه حال فارسی زبانان خارج از ایران:</p>
                        <p>
                            <ul>
                                <ol>۱. اخذ کد رهگیری از سامانه میخک</ol>
                                <ol>۲. اخذ برگه ثبت درخواست ویزا (کلیه ملیت‌ها)</ol>
                            </ul>
                        </p>
                        <p><span>همه روزه از ساعت ۸ صبح لغایت ۱۴ بعد از ظهر (بجز روزهای تعطیل)</span></p>
                        <p><span>شماره تماس و واتساپ: </span> <a style={{color: '#222'}} href="https://wa.me/+4915215716925" target={'_blank'} rel="noreferrer">۰۱۵۲۱۵۷۱۶۹۲۵</a></p>
                    </Content>
                </Section>
                <Section bgColor="white" textAlign="center">
                    <Contact />
                </Section>
            </Main>
            <Footer>
                <StaticImage src="../images/logo-brand.svg" height={24} layout="fixed" alt="footer neginfood logo" placeholder="tracedSVG" />
                <p>کلیه حقوق این سایت متعلق به شرکت نگین می‌باشد.</p>
            </Footer>
        </>
    )
}

export default IndexPage