import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled, { keyframes } from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const linearAnimation = keyframes`
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(100%);
    }
`

const ContainerBrands = styled.div`
    display: flex;
    overflow: hidden;
    min-height: 48px;
    padding: 3rem 0 3rem 0;
`

const List = styled.div`
    display: flex;
    flex: 1;
    margin: 0;
    padding: 0;
    list-style: none;
    animation: ${linearAnimation} 60s linear infinite;

    li {
        display: inline-flex;
        width: auto;
        height: 48px;
        margin: 0 2rem;
        padding: 0;
        filter: grayscale(1) opacity(50%);
        transition: all 0.3s ease-in-out;

        &:hover {
            filter: grayscale(0) opacity(100%);
        }
    }
`

export const Brands = () => {

    const data = useStaticQuery(query);

    return (
        <ContainerBrands>
            <List>{data?.brands?.nodes?.map(brand => <li key={brand.id}><GatsbyImage image={getImage(brand)} alt={brand.name + ' logo'} /></li>)}</List>
            <List>{data?.brands?.nodes?.map(brand => <li key={brand.id}><GatsbyImage image={getImage(brand)} alt={brand.name + ' logo'} /></li>)}</List>
        </ContainerBrands>
    )
}

const query = graphql`
    query {
        brands: allFile(filter: {absolutePath: {regex: "/images/brands/"}}) {
            nodes {
                id
                name
                childImageSharp {
                    gatsbyImageData(height: 48, layout: FIXED)
                }
            }
        }
    }
`

export default Brands