import * as React from 'react'
import styled from 'styled-components'

import { Navigation } from '../components/Navigation'
import { StaticImage } from 'gatsby-plugin-image'

import bg from '../images/bg.webp'

const WrapperHeader = styled.section`
    background: linear-gradient(180deg, rgba(56, 56, 56, 0.60) 80%, rgba(0, 0, 0, 0) 100%), url(${bg});
    background-position: top -663px left -666px;
    background-size: 2862px 1359px;
    background-repeat: no-repeat;
    background-color: #f9f9f9;
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 1440px;
    height: 100vh;
    max-height:696px;

    @media (max-width: 600px) {
        max-height:620px;
    }
`

const StyledHeader = styled.header`
    padding: 2rem 3rem;

    @media (max-width: 600px) {
        padding: 1rem 1rem;
    }
`

const Headline = styled.h1`
    padding-top: 4rem;
    padding-right: 2rem;
    font-size: 6rem;
    font-weight: 900;
    line-height: 10rem;
    color: rgba(237,237,237,1);

    @media (max-width: 600px) {
        padding-top: 1.618rem;
        padding-right: 1rem;
        font-size: 4rem;
        font-weight: 900;
        line-height: 7rem;
    }
`

const OptionsGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6rem;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 3rem;

    @media (max-width: 600px) {
        background: white;
        padding: 2rem 0 0;
        flex-wrap: wrap;
        bottom: 0;
        gap: 0;
    }
`

const OptionsGroupItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    @media (max-width: 600px) {
        flex: 50% 1 1;
        padding-bottom: 2rem;
    }
`


export const Header = () => (
    <>
        <WrapperHeader>
            <StyledHeader>
                <Navigation />
                <Headline>
                    ایران<br />
                    در چند قدمی شما!
                </Headline>
                <OptionsGroup>
                    <OptionsGroupItem>
                        <StaticImage src="../images/options/store.svg" alt="variety of products in the store" layout="fixed" placeholder="tracedSVG" />
                        <span>تنوع محصولات</span>
                    </OptionsGroupItem>
                    <OptionsGroupItem>
                        <StaticImage src="../images/options/discount.svg" alt="best price" layout="fixed" placeholder="tracedSVG" />
                        <span>بهترین قیمت</span>
                    </OptionsGroupItem>
                </OptionsGroup>
            </StyledHeader>
        </WrapperHeader>
    </>
)