import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const Seo = ({ title, description, image }) => {
  const { site } = useStaticQuery(graphql`query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
        defaultImage: image
      }
    }
  }`
)

const {
  defaultTitle,
  titleTemplate,
  defaultDescription,
  siteUrl,
  defaultImage
} = site.siteMetadata;

const seo = {
  title: title || defaultTitle,
  description: description || defaultDescription,
  image: `${siteUrl}${image || defaultImage}`,
  url: `${siteUrl}`,
}

  return (
    <Helmet htmlAttributes={{ lang: 'fa' }} title={seo.title} titleTemplate={titleTemplate}>
      <html lang="fa" prefix="og: https://ogp.me/ns#" />
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={seo.url} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />

      <meta property="og:locale" content="fa-IR" />
      <meta property="og:locale:alternate" content="de-DE" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />

      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "http://schema.org/",
          "@type": "Store",
          image: [
            "https://lh3.ggpht.com/p/AF1QipN-mgNpOIWQ2kcVRdnzoV2-N2YgQAx0JevhDfBL=s1024"
          ],
          "@id": "https://neginfood.de",
          name: "فروشگاه مواد غذایی نگین . فرانکفورت",
          address: {
            "@type": "PostalAddress",
            "streetAddress": "Raimundstraße 137",
            "addressRegion": "Frankfurt am Main",
            "postalCode": "60320",
            "addressCountry": "Deutschland"
          },
          "geo": {
            "@type": "GeoCoordinates",
            "latitude": 50.13956822362801,
            "longitude": 8.664990148159326
          },
          url: "https://neginfood.de",
          telephone: '+496924006625',
          "openingHoursSpecification": [
            {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
              "opens": "08:00",
              "closes": "19:00"
            },
            {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": ["Saturday"],
              "opens": "09:00",
              "closes": "18:00"
            }
          ],
        })}
      </script>

    </Helmet >
  )
}

export default Seo