import * as React from 'react'
import styled from 'styled-components'


const Section = styled.section`
    margin: 0 auto;
    padding: 0;
    font-size: 1.125rem;
    font-weight: normal;
    text-align: right;
    color: #444141;

    h3 {
        font-size: 1.125rem;
        margin: 1rem 0 1rem;
        font-weight: normal;
        color: #707070;
    }

    @media (max-width: 600px) {
        width: 100%;
        padding: 0rem;
    }
`

const Line = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0.5rem 0;
`

const Container = styled.div`
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0rem;
    gap: 2rem;

    @media (max-width: 600px) {
        justify-content: center;
        flex-direction: column;
        width: 100%;
        padding: 0rem;
    }
`

const Column = styled.div`
    flex: 1;

    @media (max-width: 600px) {
        width: 90%;
    }
`

export const Contact = () => {
    return (
        <>
            <h2 style={{marginBottom: '3rem'}} id="contact">تماس با ما</h2>
            <Container>
            <Column style={{flex: 1}}>
                <Section>
                    <h3>ساعات کاری</h3>
                    <Line>
                        <Column>
                            <span>روزهای دوشنبه تا جمعه</span>
                        </Column>
                        <Column style={{textAlign: 'left'}}>
                            <span>۸:۰۰ الی ۱۹:۰۰</span>
                        </Column>
                    </Line>
                    <Line>
                        <Column>
                            <span>روز شنبه</span>
                        </Column>
                        <Column style={{textAlign: 'left'}}>
                            <span>۹:۰۰ الی ۱۸:۰۰</span>
                        </Column>
                    </Line>
                </Section>
                <Section>
                    <Line>
                        <Column>
                            <h3>تلفن تماس</h3>
                            <a href="tel:+496924006625" style={{color: '#444444'}}><span dir="ltr">+۴۹ ۶۹ ۲۴۰۰۶۶۲۵</span></a>
                        </Column>
                        <Column>
                            <h3>ایمیل</h3>
                            <div style={{textAlign: 'left'}}>
                                <a href="mailto:info@neginfood.de" style={{color: '#444444', textAlign: 'left'}}><span>info@neginfood.de</span></a>
                            </div>
                        </Column>
                    </Line>
                </Section>
                <Section>
                    <Line>
                        <Column>
                        <h3>نشانی</h3>
                        </Column>
                        <Column>
                            <address style={{textAlign: 'left'}}>
                                M.A. Memarzadegan<br />
                                Raimundstraße. 137<br />
                                60320 Frankfurt am Main
                            </address>
                        </Column>
                    </Line>
                </Section>
            </Column>
            <Column style={{flex: 1}}>
                <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d380.12893673835526!2d8.664932359242325!3d50.138830928861445!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa042b908ee454be1!2sNegin%20Lebensmittel!5e0!3m2!1sen!2sbo!4v1615216179293!5m2!1sen!2sbo" width="100%" height="340" style={{border: '0'}} allowFullScreen="" loading="lazy"></iframe>
            </Column>
            </Container>
        </>
    )
}